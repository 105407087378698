import React, { useState } from 'react'

import * as styles from './../../css/molecules/newsTicker.module.scss'
import classNames from 'classnames'
import BackgroundImage from 'gatsby-background-image'
import { BgImage, convertToBgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import { ExclamationMarkIcon } from '../shared/icons/ExclamationMarkIcon'
import { getGermanDateFromSanityDate } from '../../utils/getGermanDateFromSanityDate'
import checkMarketOpening from '../../utils/checkMarketOpening'
import dayjs from 'dayjs'
import { sortByDate } from '../../utils'

export const NewsTicker = ({ sanityMarkets, classes }) => {
  const [hasOpening, setHasOpening] = useState(true)
  const filteredMarkets = []
  sanityMarkets.map(sanityMarket => {
    const { aboutToOpen, alreadyOpened } = checkMarketOpening(
      dayjs(),
      sanityMarket
    )
    if (aboutToOpen || alreadyOpened) filteredMarkets.push(sanityMarket)
  })

  const sortedOpenings = sortByDate(filteredMarkets, true, true)

  if (sortedOpenings.length < 1) setHasOpening(false)
  const noOpeningAvailable =
    'In den nächsten zwei Wochen sind keine Neueröffnungen geplant.'

  return (
    <div className={classNames(styles.newsTicker, classes, 'newCard')}>
      <h4 className={styles.__heading}>Neueröffungen</h4>
      {!hasOpening ? (
        <div className={styles.__noOpening}>{noOpeningAvailable}</div>
      ) : (
        sortedOpenings.slice(0, 4).map((sanityMarket, index) => {
          const openingDay = getGermanDateFromSanityDate(
            sanityMarket.openingDay
          )
          const marketBgImage = getImage(sanityMarket.image?.image?.asset)

          return (
            <>
              <div className={styles.__item} key="newsTicker">
                <div key={index} className={styles.__marketDescription}>
                  {sanityMarket.openingDelayed && (
                    <span className={styles.__marketDelayed}>
                      <ExclamationMarkIcon /> Neueröffung verschoben
                    </span>
                  )}
                  <p className={styles.__marketOpening}>{openingDay}</p>
                  <p className={styles.__marketName}>{sanityMarket.name}</p>
                  <p className={styles.__marketAddress}>
                    {sanityMarket.address.street},&nbsp;
                    {sanityMarket.address.zip}
                    &nbsp;{sanityMarket.address.city}
                  </p>
                </div>
                {marketBgImage && (
                  <BgImage
                    Tag="div"
                    image={marketBgImage}
                    className={styles.__marketImage}
                  />
                )}
              </div>
              <span className={styles.__divider}></span>
            </>
          )
        })
      )}
    </div>
  )
}
